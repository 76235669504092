import React, {useEffect} from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import s from './Total.scss';
import {OrderSummaryDataHook} from '../OrderSummary';
import settingsParams from '../../../../settingsParams';
import cx from 'classnames';
import {useSettingsWithDefaults} from '../../../../../../hooks/useSettingsWithDefaults';

const CartDisclaimers = (): React.ReactNode => {
  const {t} = useTranslation();
  const {taxOnProduct, isMainCurrency} = useControllerProps().orderStore;
  const {currencyCode} = useControllerProps().cartStore.cartModel;
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const cartDisclaimer = getSettingsWithDefaults(settingsParams.CART_V2_DISCLAIMER);

  if (isMainCurrency && !taxOnProduct && !cartDisclaimer) {
    return null;
  }

  return (
    <dl className={s.disclaimersRoot}>
      <dt>
        {!isMainCurrency && (
          <div data-hook={OrderSummaryDataHook.TotalCurrencyConverterDisclaimer} className={s.totalSubLabel}>
            {t('cart.currency_converter_disclaimer', {mainCurrency: currencyCode})}
          </div>
        )}

        {taxOnProduct && (
          <div data-hook={OrderSummaryDataHook.TotalTaxIncludedLabel} className={s.totalSubLabel}>
            {t('cart.total.taxIncluded')}
          </div>
        )}

        {cartDisclaimer && (
          <div data-hook={OrderSummaryDataHook.TotalCartDisclaimer} className={s.totalSubLabel}>
            {cartDisclaimer}
          </div>
        )}
      </dt>
    </dl>
  );
};

export const Total = () => {
  const {t} = useTranslation();
  const {shouldDisplaySubtotalTitle} = useControllerProps().orderStore;
  const {estimatedTotals, pageLoaded} = useControllerProps().cartStore;

  const classNames = cx(s.root, {[s.subTotal]: shouldDisplaySubtotalTitle});

  useEffect(
    () => {
      pageLoaded();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  return (
    <>
      <dl className={classNames}>
        <dt>
          <span data-hook={OrderSummaryDataHook.TotalTitle}>
            {shouldDisplaySubtotalTitle ? t('cart.subtotal') : t('cart.estimated_total')}
          </span>
        </dt>

        <dd
          data-hook={OrderSummaryDataHook.TotalFormattedValue}
          role="status"
          aria-live="polite"
          id="total-sum"
          data-wix-cart-total-price={OrderSummaryDataHook.TotalFormattedValue}>
          {estimatedTotals.priceSummary.total.formattedConvertedAmount}
        </dd>
      </dl>
      <CartDisclaimers />
    </>
  );
};
